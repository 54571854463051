<template>
    <v-card
      outlined
      :img="require('../../assets/images/Home (1).jpg')"
      class="white mx-auto homePage pa-0"
      min-height="100vh"
      min-width="100vw"
      elevation="0"
    >

<v-container>

      <v-row class="">
        <v-col
          class="left-side"
          cols="12"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
        >
          <v-row
            :class="
              $vuetify.breakpoint.mobile ? 'justify-center' : ''
            "
          >
            <v-img
              :src="require('../../assets/logo.png')"
              max-width="120"
              class="mt-4 mb-10 ms-md-4"
              contain
            />
          </v-row>
          <div v-if="paymentId">
            <div v-if="isPaymentSuccess">
              <h1
                class="text-h6"
              >
                {{ $t("payment.success") }}
              </h1>
              <v-btn
                class="primary mt-6"
                :to="{ name: 'Home' }"
                >Go to Home</v-btn
              >
            </div>
            <div v-else>
              <h1
                class="text-subtitle-1 text-sm-h6"
              >
                {{ $t("payment.failed") }}
              </h1>
              <v-btn
                :loading="btnLoading"
                @click="retryPayment"
                class="error mt-6"
                >Try Again</v-btn
              >
            </div>
          </div>
          <div v-else>
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>

</v-container>
    </v-card>

</template>

<script>
import { customerCheckout } from "../methods/customerCheckout";

export default {
  data() {
    return {
      isPaymentSuccess: null,
      paymentId: null,
      btnLoading: false,
    };
  },
  methods: {
    retryPayment() {
      this.btnLoading = true;
      let retryPaymentDetails = JSON.parse(
        localStorage.getItem("retryPaymentDetails")
      );
      customerCheckout(retryPaymentDetails).then((response) => {
        this.btnLoading = false;
        if (response.data.checkoutCustomerPackage.paymentUrl) {
          window.open(
            response.data.checkoutCustomerPackage.paymentUrl,
            "_self"
          );
        }
      });
    },
  },
  apollo: {
    checkPaymentStatus() {
      return {
        query: require("@/payments/api/queries/check_payment_status.graphql"),
        variables: { paymentId: this.$route.query.tap_id },
        result({ data }) {
          if (data) {
            debugger
            const { paymentStatus, paymentId } = data.checkPaymentStatus;
            this.isPaymentSuccess = paymentStatus;
            this.paymentId  = paymentId

          }
        },
        skip() {
          return !this.$route.query.tap_id;
        },
      };
    },
  },
};
</script>

<style scoped>
.left-side {
  margin-top: 15%;
}
</style>