<template>
  <v-container fluid class="pa-0 primary">
    <dense-base-card>
      <template slot="grid-left">
        <ImageCard />
      </template>
      <template slot="grid-right">
        <SignUpForm />
      </template>
    </dense-base-card>
  </v-container>
</template>

<script>
export default {
  components: {
    SignUpForm: () => import("@/user/components/signUp/SignUpForm"),
    ImageCard: () => import("@/user/components/signUp/ImageCard"),
    "dense-base-card": () => import("@/core/components/DenseBaseCard"),
  },
};
</script>

<style>
</style>