<template>
<dense-base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
     <package-form/>
    </template>
  </dense-base-card>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  components: {
    "dense-base-card": () => import("@/core/components/DenseBaseCard"),
    "package-form": () => import("@/package/components/PackageForm"),
    "image-card": () => import("@/menu/components/ImageCard"),
  }
}
</script>

<style>

</style>
