<template>
  <base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
      <sign-up-form />
    </template>
  </base-card>
</template>

<script>
export default {
  components: {
    "base-card": () => import("@/core/components/BaseCard"),
    "sign-up-form": () => import("@/user/components/userPhone/SignInForm"),
    "image-card": () => import("@/user/components/userPhone/ImageCard"),
  },
  data() {
    return {
      show: false,
      userDetails: {},
      btn_loading: false,
      errorFromApi: null,
    };
  },
};
</script>

<style>
/* .inputPhone input[type="number"] {
  -moz-appearance: textfield;
}
.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
} */
</style>