<template>
<dense-base-card>
    <template slot="grid-left">
     <image-card />
    </template>
    <template slot="grid-right">
      <health-form/>
    </template>
  </dense-base-card>
</template>

<script>
export default {
  components: {
    "dense-base-card": () => import("@/core/components/DenseBaseCard"),
    "health-form": () => import("@/health/components/HealthForm"),
    "image-card": () => import("@/menu/components/ImageCard"),
  }
}
</script>

<style>

</style>