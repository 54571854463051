<template>
<base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
     <reset-password-form/>
    </template>
  </base-card>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  components: {
    "base-card": () => import("@/core/components/BaseCard"),
    "reset-password-form": () => import("@/user/components/resetPassword/ResetPasswordForm"),
    "image-card": () => import("@/user/components/userPhone/ImageCard"),
  }
}
</script>

<style>

</style>