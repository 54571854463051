<template>
    <dense-base-card>
      <template slot="grid-left">
            <ImageCard/>
      </template>
      <template slot="grid-right">
            <MenuCard/>
      </template>
    </dense-base-card>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  components: {
MenuCard: () => import("@/menu/components/MenuCard"),
    ImageCard: () => import("@/menu/components/ImageCard"),
   "dense-base-card": () => import("@/core/components/DenseBaseCard")
  }
}
</script>

<style>

</style>
