<template>
<base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
            <otp-form/>
    </template>
  </base-card>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  components: {
    "base-card": () => import("@/core/components/BaseCard"),
    "otp-form": () => import("@/user/components/passwordOtp/PhoneOtp"),
    "image-card": () => import("@/user/components/userPhone/ImageCard"),
  }
}
</script>

<style>

</style>