import { apolloClient } from "@/plugins/vue-apollo.js";

const customerCheckout = (_variables) => {
  // , limit, skip
  let responseFromApi;
  let variables = _variables;
  responseFromApi = apolloClient
    .mutate({
      mutation: require("../api/mutations/customer_checkout.graphql"),
      variables: variables,
    })
    .catch(error => {
      return error;
    });
  return responseFromApi;
};
export { customerCheckout };
