<template>
  <base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
      <verify-form :isForgotPassword="$route.params.isForgotPassword" />

    </template>
  </base-card>
</template>

<script>
export default {
  components: {
    "base-card": () => import("@/core/components/BaseCard"),
    "verify-form": () =>
      import("@/user/components/verifyPhone/VerifyPhoneForm"),
    "image-card": () => import("@/user/components/userPhone/ImageCard"),
  },
};
</script>

<style>
</style>