<template>
    <v-card
      :img="require('../../assets/images/Home (1).jpg')"
      outlined
      height="100vh"
      class="white mx-auto  homePage"
      elevation="0"
    >
    <v-container>

      <v-row class="py-12 ps-5">
        <div>
          <span @click="signout()"
            class="custom-font"
            style="cursor: pointer">
            <font-awesome-icon
              class=" black--text mt-1"
              :icon="['fas', 'sign-out-alt']"
            ></font-awesome-icon>
            <label
              class="black--text"
              style="cursor: pointer"
              >{{ $t('common.logout') }}</label
            >
          </span>
        </div>
      </v-row>
      <v-row class="">
        <v-col class="left-side" cols="12" md="6" sm="12"
          :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
         <v-row :class="$vuetify.breakpoint.mobile ? 
            'justify-center' : ''">
            <v-img
              :src="require('../../assets/logo.png')"
              max-width="100"
              class="mt-4 mb-10 ms-md-4"
              contain
            />
          </v-row>
          <h1 class="text-h6 text-md-h4 mb-2"
            :class="$vuetify.breakpoint.mobile ?
              '' : ''">
            {{ $t('home.thanksForSignin') }}
          </h1>
          <h1 class="text-body-2 text-md-body-1"
            :class="$vuetify.breakpoint.mobile ?
              '' : ''">
            {{ $t('home.downloadApp') }}
          </h1>
          
          <v-row class=" mx-0 mt-0" :class="$vuetify.breakpoint.mobile ? 
            'justify-center' : ''">
            <v-img
              :src="require('../../assets/apple-store.png')"
              max-width="235"
              class="pointer"
              @click="goToApp"
              contain
            />
            <v-img
              :src="require('../../assets/play-store.png')"
              max-width="130"
              class="pointer"
              @click="goToApp"
              contain
            />
          </v-row>
        </v-col>
        <v-col cols="12" md="6" sm="12"></v-col>
      </v-row>

    </v-container>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  methods: {
    signout () {
      localStorage.removeItem('Customer')
      localStorage.removeItem('apollo-done-token')
      this.$router.push('/')
    },
    goToApp () {
      window.open(
        'https://play.google.com/store/apps/details?id=com.cocopalms.dietpro',
        '_blank'
      )
    }
  }
}
</script>

<style scoped>
.left-side {
  margin-top: 5%;
}
</style>