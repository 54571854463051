<template>
<dense-base-card>
    <template slot="grid-left">
      <image-card />
    </template>
    <template slot="grid-right">
      <payment-form/>
    </template>
  </dense-base-card>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  components: {
    "dense-base-card": () => import("@/core/components/DenseBaseCard"),
    "payment-form": () => import("@/payments/components/PaymentForm"),
    "image-card": () => import("@/user/components/signUp/ImageCard"),
  }
}
</script>

<style>

</style>
